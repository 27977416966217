<template>
  <b-modal
    id="modal-contacts"
    :ok-title="$t('meeting_point.confirm')"
    :cancel-title="$t('Abort')"
    cancel-variant="outline-secondary"
    hideHeaderClose
    no-close-on-backdrop
    @ok="confirmData"
    @cancel="resetModal"
    @show="showModal"
    size="lg"
    centered
  >
    <b-overlay :show="show" rounded="sm">
      <b-row>
        <b-col md="6">
          <b-form-group
            :label="$t('pin_point.Name_and_Lastname')"
            label-for="name"
          >
            <b-form-input
              id="name"
              :placeholder="$t('pin_point.Name_Lastname')"
              v-model="fields.fullname"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group label-for="role" :label="$t('pin_point.Business_role')">
            <div class="form-label-group">
              <b-form-input
                id="role"
                :placeholder="$t('pin_point.business_placeholder')"
                v-model="fields.business_role"
              />
            </div>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            :label="$t('pin_point.phone_number')"
            label-for="phone-number"
          >
            <b-form-input
              id="phone-number"
              :placeholder="$t('pin_point.phone_number')"
              v-model="fields.telephone"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            :label="$t('pin_point.whatsapp_number')"
            label-for="whatsapp-number"
          >
            <b-form-input
              id="whatsapp-number"
              :placeholder="$t('pin_point.phone_number')"
              v-model="fields.whatsapp"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label-for="mc-email"
            :label="$t('pin_point.Contact_email')"
          >
            <div class="form-label-group">
              <b-form-input
                id="mc-email"
                type="email"
                :placeholder="$t('email')"
                v-model="fields.email"
              />
            </div>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label-for="privacy"
            :label="$t('pin_point.Privacy_policy_url')"
          >
            <div class="form-label-group">
              <b-form-input
                id="privacy"
                :placeholder="$t('pin_point.privacy_placeholder')"
                v-model="fields.privacy_policy_url"
              />
            </div>
          </b-form-group>
        </b-col>
        <b-col md="6" class="input-form-style">
          <b-form-group :label="$t('pin_point.Contact_image')">
            <b-form-file
              v-model="file"
              :placeholder="$t('any_file_select')"
              accept=".jpg, .png"
              @input="openEditorPreview(file, 'contacts')"
            />
          </b-form-group>
          <b-img
            :src="preview_contacts"
            id="preview-contacts"
            class="preview-img"
          ></b-img>
        </b-col>
      </b-row>
    </b-overlay>
  </b-modal>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BModal,
  BFormFile,
  BImg,
  BOverlay,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  props: ["data"],
  name: "modal-contacts",
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BModal,
    BFormFile,
    BImg,
    BOverlay,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      fields: {
        email: "",
        whatsapp: "",
        telephone: "",
        fullname: "",
        business_role: "",
        privacy_policy_url: "",
      },
      file: null,
      preview_contacts: null,
      show: false,
    };
  },
  methods: {
    showModal() {
      this.show = true;
      this.fields = { ...this.data };
      this.original_fields = { ...this.data };
      if (this.fields.image) {
        this.preview_contacts = this.fields.image.url;
      }
      this.show = false;
    },
    confirmData() {
      this.show = true;
      let contacts_data = {
        email: this.fields.email,
        whatsapp: this.fields.whatsapp,
        telephone: this.fields.telephone,
        fullname: this.fields.fullname,
        business_role: this.fields.business_role,
        privacy_policy_url: this.fields.privacy_policy_url,
      };
      if (this.file) {
        contacts_data.image = this.file;
      }
      this.$emit("onConfirm", contacts_data);
      this.$bvModal.hide("modal-contacts");
    },
    openEditorPreview(file, type) {
      this.$emit("open_editor", file, type);
    },
    resetModal() {
      this.file = null;
      this.$bvModal.hide("modal-contacts");
    },
  },
};
</script>
