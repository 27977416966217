<template>
  <b-modal
    id="modal-webinars"
    :ok-title="$t('meeting_point.confirm')"
    :cancel-title="$t('Abort')"
    cancel-variant="outline-secondary"
    no-close-on-backdrop
    hideHeaderClose
    @ok="confirmData"
    @cancel="resetModal"
    @show="showModal"
    size="xl"
    centered
  >
    <b-overlay :show="show" rounded="sm">
      <div>
        <b-form
          ref="form"
          :style="{ height: trHeight }"
          class="repeater-form"
          @submit.prevent="addItem"
        >
          <b-row
            v-for="(item, index) in webinar_list"
            :id="item.id"
            :key="item.id"
            ref="row"
          >
            <b-col md="4">
              <b-form-group
                :label="$t('pin_point.Description')"
                :label-for="`workshop_title_${item.id}`"
              >
                <b-form-input
                  :id="`workshop_title_${item.id}`"
                  type="text"
                  :placeholder="$t('pin_point.insert_description')"
                  v-model="item.description"
                />
              </b-form-group>
            </b-col>

            <b-col md="2">
              <b-form-group
                :label="$t('pin_point.workshop_live_start_time')"
                :label-for="`date-time-start_${item.id}`"
              >
                <flat-pickr
                  :id="`date-time-start_${item.id}`"
                  placeholder="YYYY-MM-DD HH:MM"
                  class="form-control"
                  v-model="item.start"
                  :config="{
                    enableTime: true,
                    dateFormat: 'Y-m-d H:i',
                    time_24hr: true,
                  }"
                />
              </b-form-group>
            </b-col>

            <b-col md="2">
              <b-form-group
                :label="$t('pin_point.workshop_live_end_time')"
                :label-for="`date-time-end_${item.id}`"
              >
                <flat-pickr
                  :id="`date-time-end_${item.id}`"
                  placeholder="YYYY-MM-DD HH:MM"
                  class="form-control"
                  v-model="item.end"
                  :config="{
                    enableTime: true,
                    dateFormat: 'Y-m-d H:i',
                    time_24hr: true,
                  }"
                />
              </b-form-group>
            </b-col>

            <b-col md="2">
              <b-form-group
                :label="$t('pin_point.Link')"
                :label-for="`workshop_link_${item.id}`"
              >
                <b-form-input
                  :id="`workshop_link_${item.id}`"
                  type="text"
                  :placeholder="$t('pin_point.Insert_link')"
                  v-model="item.url"
                />
              </b-form-group>
            </b-col>

            <b-col lg="2" md="3" class="mb-50">
              <b-button
                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                variant="outline-danger"
                class="mt-0 mt-md-2"
                @click="removeItem(index)"
              >
                <feather-icon icon="XIcon" class="mr-25" />
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </div>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        @click="addItem"
      >
        <feather-icon icon="PlusIcon" class="mr-25" />
        <span>{{ $t("pin_point.add") }}</span>
      </b-button>
    </b-overlay>
  </b-modal>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BModal,
  BOverlay,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import "@core/scss/vue/libs/vue-flatpicker.scss";
import { heightTransition } from "@core/mixins/ui/transition";
import Ripple from "vue-ripple-directive";

export default {
  props: ["data"],
  name: "modal-webinars",
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BModal,
    BOverlay,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      webinar_list: [
        {
          id: 1,
          description: "",
          start: null,
          end: null,
          url: null,
        },
      ],
      show: false,
      nextItemId: 2,
    };
  },
  mounted() {
    this.initTrHeight();
  },
  created() {
    window.addEventListener("resize", this.initTrHeight);
  },
  destroyed() {
    window.removeEventListener("resize", this.initTrHeight);
  },
  methods: {
    showModal() {
      this.show = true;
      if (this.data && this.data.length > 0) {
        this.webinar_list = [];
        for (let i = 0; i < this.data.length; i++) {
          const element = this.data[i];
          this.nextItemId++;
          this.webinar_list.push({
            id: i + 1,
            description: element.description,
            start: element.start,
            end: element.end,
            url: element.url,
          });
        }
      }
      this.show = false;
    },
    confirmData() {
      this.$emit("onConfirm", this.webinar_list);
      this.$bvModal.hide("modal-webinars");
    },
    resetModal() {
      this.$bvModal.hide("modal-webinars");
    },
    addItem() {
      this.webinar_list.push({
        id: this.nextItemId++,
        description: "",
        start: null,
        end: null,
        url: "",
      });
      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight);
      });
    },
    removeItem(index) {
      this.webinar_list.splice(index, 1);
      this.trTrimHeight(this.$refs.row[0].offsetHeight);
    },
    initTrHeight() {
      this.trSetHeight(null);
      this.$nextTick(() => {
        if (typeof this.$refs.form !== "undefined") {
          this.trSetHeight(this.$refs.form.scrollHeight);
        }
      });
    },
  },
};
</script>

<style>
.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}
</style>
