<template>
  <div>
    <b-row>
      <b-col>
        <b-form-group
          :label="$t('pin_point.screen_label')"
          label-for="screen_input"
        >
          <b-input-group>
            <b-input-group-prepend>
              <b-dropdown :text="dropdown_label" variant="outline-primary">
                <b-dropdown-item @click="upload_video">{{
                  $t("pin_point.Video")
                }}</b-dropdown-item>
                <b-dropdown-item @click="insert_youtube_link">{{
                  $t("pin_point.Youtube")
                }}</b-dropdown-item>
                <b-dropdown-item @click="upload_image">{{
                  $t("pin_point.Image")
                }}</b-dropdown-item>
              </b-dropdown>
            </b-input-group-prepend>
            <b-form-input
              v-show="!(add_video || add_youtube_link || add_image_file)"
              disabled
              id="screen_input"
            />
            <b-form-file
              accept=".jpg, .png"
              v-show="add_image_file"
              id="image_file"
              v-model="image_file"
              :placeholder="$t('pin_point.image_placeholder')"
              @input="openEditorScreen(image_file, 'screen')"
            />
            <b-form-file
              accept=".mp4"
              v-show="add_video"
              id="video_file"
              v-model="video_file"
              :placeholder="$t('pin_point.video_placeholder')"
              @input="show_preview_link = null"
            />
            <b-form-input
              :placeholder="$t('pin_point.youtube_placeholder')"
              v-show="add_youtube_link"
              id="youtube_link"
              v-model="youtube_link"
            />
          </b-input-group>
        </b-form-group>
        <b-alert show v-show="add_video" variant="warning">
          {{ $t("pin_point.video_size_warning") }}
        </b-alert>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-img
          v-if="add_image_file"
          v-model="image"
          :src="preview_screen"
          id="screen-preview-container"
          class="preview-img"
        />
      </b-col>
      <b-col md="12" v-if="add_youtube_link">
        <a :href="youtube_link" target="_blank">
          <feather-icon icon="FileIcon" /> {{ youtube_link }}
        </a>
      </b-col>
      <b-col md="12" v-if="show_preview_link">
        <a :href="file_url" target="_blank">
          <feather-icon icon="FileIcon" /> {{ file_name }}
        </a>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BForm,
  BButton,
  BFormFile,
  BDropdown,
  BDropdownItem,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
  BFormInput,
  BImg,
  BAlert,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  props: ["screen"],
  name: "screen",
  components: {
    BRow,
    BCol,
    BFormGroup,
    BForm,
    BButton,
    BFormFile,
    BDropdown,
    BDropdownItem,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BFormInput,
    BImg,
    BAlert,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      image: null,
      type: null,
      image_file: null,
      video_file: null,
      dropdown_label: this.$t("pin_point.Action"),
      youtube_link: null,
      file_name: "",
      file_url: null,
      show_preview_link: null,
      add_video: false,
      add_youtube_link: false,
      add_image_file: false,
      preview_screen: null,
      video_link: false,
    };
  },
  methods: {
    openEditorScreen(file, type) {
      this.$emit("open_editor", file, type);
    },
    emptyField() {
      this.add_image_file = null;
    },
    upload_video() {
      if (this.video_file) {
        this.show_preview_link = false;
      } else {
        this.show_preview_link = true;
      }
      this.add_video = true;
      this.add_youtube_link = false;
      this.add_image_file = false;
      this.image_file = null;
      this.youtube_link = null;
      this.image = null;
      this.dropdown_label = this.$t("pin_point.Video");
    },
    insert_youtube_link() {
      this.show_preview_link = false;
      this.add_video = false;
      this.add_youtube_link = true;
      this.add_image_file = false;
      this.video_file = null;
      this.image_file = null;
      this.image = null;
      this.dropdown_label = this.$t("pin_point.Youtube");
    },
    upload_image() {
      this.show_preview_link = false;
      this.add_video = false;
      this.add_youtube_link = false;
      this.add_image_file = true;
      this.video_file = null;
      this.youtube_link = null;
      this.dropdown_label = this.$t("pin_point.Image");
    },
  },
  watch: {
    image_file: function() {
      this.$emit("input", this.image_file);
    },
    video_file: function() {
      this.$emit("input", this.video_file);
    },
    youtube_link: function() {
      this.$emit("input", this.youtube_link);
    },
    screen() {
      if (this.screen) {
        if (this.screen.link) {
          this.insert_youtube_link();
          this.youtube_link = this.screen.link;
        }
        if (this.screen.image) {
          this.upload_image();
          this.preview_screen = this.screen.image.url;
        }
        if (this.screen.video) {
          this.upload_video();
          this.show_preview_link = true;
          this.file_url = this.screen.video.url;
          this.file_name = this.screen.video.name;
        }
      }
    },
  },
};
</script>
