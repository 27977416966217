<template>
  <div>
    <ValidationObserver ref="meeting_point" v-slot="{ invalid }">
      <b-row>
        <b-col md="7" lg="8" xl="8">
          <b-row>
            <b-col md="12" lg="12">
              <b-card-code :title="$t('info')">
                <b-row>
                  <b-col class="col-6">
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                      name="Name"
                    >
                      <b-form-group
                        :label="$t('meeting_point.point_name') + ' *'"
                        label-for="point_name"
                      >
                        <b-form-input
                          id="point_name"
                          :placeholder="$t('insert_name')"
                          v-model="meeting_point.name"
                          type="text"
                        />
                      </b-form-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>
                  <b-col class="col-6">
                    <b-form-group
                      :label="$t('meeting_point.logo_point') + ' *'"
                      label-for="point_logo"
                    >
                      <b-form-file
                        accept=".jpg, .png"
                        id="point_logo"
                        ref="file-input"
                        :placeholder="$t('any_file_select')"
                        v-model="logo_point"
                        @input="openEditor(logo_point, 'logo_point')"
                      />
                      <b-img
                        v-if="meeting_point.logo_url"
                        :src="meeting_point.logo_url"
                        id="preview-logo-point"
                        class="preview-img"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="col-6">
                    <screen
                      ref="screen"
                      @open_editor="openEditor"
                      :screen="meeting_point.screen"
                      v-model="screen_data"
                    />
                  </b-col>
                  <b-col class="col-6">
                    <pdf
                      ref="pdf"
                      :pdf_name="meeting_point.pdf_name"
                      :pdf_url="meeting_point.pdf_url"
                      v-model="pdf_file"
                    />
                  </b-col>
                </b-row>
              </b-card-code>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="col-5">
              <b-card-code class="col-12">
                <b-table
                  hover
                  small
                  :fields="fields"
                  :items="modals"
                  responsive="sm"
                  class="table_stand"
                >
                  <template #cell(name)="data">
                    {{ data.item.label }}
                  </template>
                  <template #cell(action)="data">
                    <b-button
                      class="btn-icon rounded-circle"
                      variant="flat-primary"
                      @click="
                        $bvModal.show(
                          'modal-' + data.item.slug,
                          data.item.fields
                        )
                      "
                    >
                      <feather-icon icon="EditIcon" />
                    </b-button>
                  </template>
                </b-table>
              </b-card-code>
            </b-col>
          </b-row>
        </b-col>

        <b-col md="5" lg="4" xl="4">
          <b-card>
            <b-row>
              <b-col md="6">
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="Start"
                >
                  <b-form-group
                    :label="$t('pin_point.workshop_live_start_time') + ' *'"
                    :label-for="`date-time-start_${meeting_point.id}`"
                  >
                    <flat-pickr
                      :id="`date-time-start_${meeting_point.id}`"
                      placeholder="YYYY-MM-DD HH:MM"
                      class="form-control"
                      v-model="meeting_point.start"
                      :state="errors.length > 0 ? false : null"
                      :config="{
                        enableTime: true,
                        dateFormat: 'Y-m-d H:i',
                        time_24hr: true,
                      }"
                    />
                  </b-form-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
              <b-col md="6">
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="End"
                >
                  <b-form-group
                    :label="$t('pin_point.workshop_live_end_time') + ' *'"
                    :label-for="`date-time-end_${meeting_point.id}`"
                  >
                    <flat-pickr
                      :id="`date-time-end_${meeting_point.id}`"
                      placeholder="YYYY-MM-DD HH:MM"
                      class="form-control"
                      v-model="meeting_point.end"
                      :state="errors.length > 0 ? false : null"
                      :config="{
                        enableTime: true,
                        dateFormat: 'Y-m-d H:i',
                        time_24hr: true,
                      }"
                    />
                  </b-form-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="col-12">
                <b-form-group :label="$t('Link')">
                  <b-form-input
                    v-model="meeting_point.url"
                    :placeholder="$t('Link')"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="col-12">
                <b-card-text class="col-form-label">{{
                  $t("meeting_point.Link_meeting")
                }}</b-card-text>
                <div class="d-flex stand-link">
                  <b-form-group class="mb-0 mr-1">
                    <b-form-input
                      v-model="meeting_point_link"
                      disabled
                      :placeholder="$t('Link')"
                    />
                  </b-form-group>
                  <b-button
                    v-clipboard:copy="meeting_point_link"
                    v-clipboard:success="onCopyClip"
                    v-clipboard:error="onErrorClip"
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="primary"
                  >
                    {{ $t("copy") }}
                  </b-button>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="col-12">
                <b-form-group :label="$t('State') + ' *'" class="mt-2">
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="State"
                  >
                    <b-form-select
                      v-model="meeting_point.active"
                      :options="state_options"
                      :state="errors.length > 0 ? false : null"
                    />
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="col-12">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  block
                  variant="primary"
                  class="mt-2"
                  @click.prevent="save"
                  :disabled="invalid"
                >
                  {{ $t("save_edit") }}
                </b-button>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </ValidationObserver>

    <modal-contacts
      ref="contacts"
      :data="meeting_point.contacts"
      @open_editor="openEditor"
      @onConfirm="storeContactsData"
    ></modal-contacts>
    <modal-webinar
      ref="webinars"
      :data="meeting_point.webinars"
      @onConfirm="storeWebinarsData"
    ></modal-webinar>

    <modal-photo-editor
      :image="image"
      :type="type"
      :width="width"
      :height="height"
      @onSave="showPreview"
      @close="abortImageEdit"
    ></modal-photo-editor>
  </div>
</template>

<script>
import BCardCode from "@core/components/b-card-code";
import {
  BBadge,
  BTable,
  BFormFile,
  BButton,
  BCard,
  BCardText,
  BLink,
  BFormInput,
  BRow,
  BCol,
  BFormGroup,
  BModal,
  VBModal,
  BFormSelect,
  BImg,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
  BAlert,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import "@core/scss/vue/libs/vue-flatpicker.scss";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";

import screen from "./fields/screen.vue";
import pdf from "./fields/pdf.vue";
import modalContacts from "./modals/contacts.vue";
import modalWebinar from "./modals/webinars.vue";
import ModalPhotoEditor from "../editor.vue";

import { FAIRERS_URL } from "@variables";

import {
  createMeetingPoint,
  getMeetingPoint,
  updateMeetingPoint,
} from "@api/meetingpoints";

export default {
  components: {
    BCardCode,
    BBadge,
    BTable,
    BFormFile,
    BButton,
    BCard,
    BCardText,
    BLink,
    BFormInput,
    BRow,
    BCol,
    BFormGroup,
    BModal,
    VBModal,
    BFormSelect,
    BImg,
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
    ModalPhotoEditor,
    flatPickr,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BAlert,
    screen,
    pdf,
    modalContacts,
    modalWebinar,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      meeting_point: {
        name: null,
        logo: null,
        logo_url: null,
        startdate: null,
        enddate: null,
        url: "",
        screen: null,
        pdf: null,
        contacts: null,
        webinars: null,
        active: null,
      },
      meeting_point_link: "",
      state_options: [
        { value: true, text: this.$t("status_published") },
        { value: false, text: this.$t("status_inactive") },
      ],
      fields: [
        { key: "name", label: this.$t("field_label_name") },
        {
          key: "action",
          thStyle: "width:50px",
          label: this.$t("field_label_actions"),
        },
      ],
      modals: [
        {
          label: "Contacts",
          slug: "contacts",
        },
        {
          label: "Webinars",
          slug: "webinars",
        },
      ],
      logo_point: null,
      image: null,
      type: null,
      width: null,
      height: null,
      crop_limit: {
        logo_point: {
          width: 840,
          height: 300,
        },
        contacts: {
          width: 160,
          height: 160,
        },
        screen: {
          width: 1280,
          height: 720,
        },
      },
      screen_data: null,
      pdf_file: null,
      contacts_data: [],
    };
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      let $this = this;
      if (this.$route.params.id != "add") {
        getMeetingPoint(this.$route.params.id)
          .then((data) => {
            $this.meeting_point = data;
            $this.meeting_point_link = `${FAIRERS_URL}/?reload=1&project=meeting&id=${$this.$route.params.id}`;
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    openEditor(file, type) {
      if (file != null) {
        this.image = file;
        this.type = type;
        this.width = this.crop_limit[type].width;
        this.height = this.crop_limit[type].height;
        this.$bvModal.show("modal-editor");
      }
    },
    showPreview(type, imageUrl, image = null) {
      if (type == "logo_point") {
        this.meeting_point.logo = image;
        this.meeting_point.logo_url = imageUrl;
      } else if (type == "contacts") {
        this.$refs.contacts.file = image;
        this.$refs.contacts.preview_contacts = imageUrl;
      } else if (type == "screen") {
        this.$refs.screen.image_file = image;
        this.$refs.screen.preview_screen = imageUrl;
      }
    },
    abortImageEdit(type) {
      if (type == "logo_point") {
        this.logo_point = null;
      } else if (type == "contacts") {
        this.$refs.contacts.emptyField();
      } else if (type == "screen") {
        this.screen_data = null;
        this.$refs.screen.emptyField();
      }
    },
    onCopyClip() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: this.$t("text_copied"),
          icon: "BellIcon",
        },
      });
    },
    onErrorClip() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: this.$t("failed_text_copied"),
          icon: "BellIcon",
        },
      });
    },
    storeContactsData(data) {
      this.meeting_point.contacts = data;
    },
    storeWebinarsData(data) {
      this.meeting_point.webinars = data;
    },
    save() {
      const $this = this;
      this.$refs.meeting_point.validate().then((success) => {
        if (success) {
          $this.prepareDataToSave();
        }
      });
      return false;
    },
    prepareDataToSave() {
      let formData = new FormData();
      formData.append("name", this.meeting_point.name);
      if (this.meeting_point.logo) {
        formData.append("logo", this.meeting_point.logo);
      }
      formData.append("start", this.meeting_point.start);
      formData.append("end", this.meeting_point.end);
      formData.append("url", this.meeting_point.url);
      if (this.screen_data) {
        this.prepareScreenData(formData);
      }
      if (this.pdf_file) {
        formData.append("pdf", this.pdf_file);
      }
      if (this.meeting_point.contacts) {
        this.prepareContactsData(formData);
      }
      if (this.meeting_point.webinars) {
        this.prepareWebinarsData(formData);
      }
      formData.append("active", this.meeting_point.active ? 1 : 0);
      if (this.$route.params.id == "add") {
        this.create(formData);
      } else {
        this.update(formData);
      }
    },
    prepareScreenData(formData) {
      if (this.$refs.screen.add_image_file) {
        formData.append(`screen[image]`, this.screen_data);
      } else if (this.$refs.screen.add_youtube_link) {
        formData.append(`screen[link]`, this.screen_data);
      } else if (this.$refs.screen.add_video) {
        formData.append(`screen[video]`, this.screen_data);
      }
    },
    prepareContactsData(formData) {
      for (const key in this.meeting_point.contacts) {
        if (
          key != "image" ||
          typeof this.meeting_point.contacts.image.url == "undefined"
        ) {
          formData.append(`contacts[${key}]`, this.meeting_point.contacts[key]);
        }
      }
    },
    prepareWebinarsData(formData) {
      for (let i = 0; i < this.meeting_point.webinars.length; i++) {
        formData.append(
          `webinars[${i}][description]`,
          this.meeting_point.webinars[i].description
        );
        if (this.meeting_point.webinars[i].start == null) {
          formData.append(`webinars[${i}][start]`, "");
        } else {
          formData.append(
            `webinars[${i}][start]`,
            this.meeting_point.webinars[i].start
          );
        }
        if (this.meeting_point.webinars[i].end == null) {
          formData.append(`webinars[${i}][end]`, "");
        } else {
          formData.append(
            `webinars[${i}][end]`,
            this.meeting_point.webinars[i].end
          );
        }
        formData.append(
          `webinars[${i}][url]`,
          this.meeting_point.webinars[i].url
        );
      }
    },
    create(formData) {
      let $this = this;
      createMeetingPoint(formData)
        .then((data) => {
          $this.showSuccessMessage();
          $this.$router.push({ path: `/meeting_points/${data.id}` });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    update(formData) {
      let $this = this;
      updateMeetingPoint(this.$route.params.id, formData)
        .then((data) => {
          $this.showSuccessMessage();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    showSuccessMessage() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: this.$t("save_complete"),
          icon: "EditIcon",
          variant: "success",
        },
      });
    },
  },
};
</script>
