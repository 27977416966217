<template>
  <b-row>
    <b-col md="12">
      <b-form-group
        :label="$t('pin_point.Add_company_profile')"
        label-for="company_profile"
      >
        <b-form-file
          id="company_profile"
          v-model="file"
          :placeholder="$t('any_file_select')"
          accept=".pdf"
          @input="filePreview"
        />
      </b-form-group>
    </b-col>
    <b-col md="6" v-if="file_url">
      <a :href="file_url" target="_blank">
        <feather-icon icon="FileIcon" /> {{ file_name }}
      </a>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BForm,
  BButton,
  BFormFile,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  props: ["pdf_name", "pdf_url"],
  name: "pdf",
  components: {
    BRow,
    BCol,
    BFormGroup,
    BForm,
    BButton,
    BFormFile,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      file: null,
      file_url: this.pdf_url,
      file_name: this.pdf_name,
    };
  },
  methods: {
    filePreview() {
      this.file_url = null;
    },
  },
  watch: {
    file: function() {
      this.$emit("input", this.file);
    },
    pdf_name() {
      this.file_name = this.pdf_name;
    },
    pdf_url() {
      this.file_url = this.pdf_url;
    },
  },
};
</script>
